import React from "react"
import ReactDOM from "react-dom"
import Flickity from "react-flickity-component"

import "../assets/css/flickity.css"

import centurypropertydevelopments from "../images/logos/properties/centurypropertydevelopments.png"
import galenciaproperties from "../images/logos/properties/galenciaproperties.png"
import renprop from "../images/logos/properties/renprop.png"
import balwin from "../images/logos/properties/balwin.png"
import yohome from "../images/logos/properties/yohome.png"
import southpoint from "../images/logos/properties/southpoint.png"
import lynn from "../images/logos/properties/lynn.png"
import signatura from "../images/logos/properties/signatura.png"
import realgrowth from "../images/logos/properties/realgrowth.png"
import cosmopolitan from "../images/logos/properties/cosmopolitan.png"
import calgrom3 from "../images/logos/properties/calgrom3.png"
import ryanjoffe from "../images/logos/properties/ryanjoffe.png"
import innercityflats from "../images/logos/properties/innercityflats.png"
import houss from "../images/logos/properties/houss.png"

const flickityOptions = {
  autoPlay: 2000,
  prevNextButtons: false,
  pageDots: false,
}

const Logo = ({ image, url }) => {
  return (
    <div className="w-1/2 md:w-1/4">
      <div className="h-10 md:h-12 w-full flex mb-6">
        <img
          className="h-full w-auto mx-auto filter grayscale hover:grayscale-0"
          src={image}
          alt=""
        />
      </div>
    </div>
  )
}

function DevelopersTrustedBrands() {
  return (
    <Flickity className="w-full" options={flickityOptions}>
      <div className="w-full flex flex-wrap">
        <Logo image={centurypropertydevelopments} />
        <Logo image={galenciaproperties} />
        <Logo image={renprop} />
        <Logo image={balwin} />
      </div>

      <div className="w-full flex flex-wrap">
        <Logo image={yohome} />
        <Logo image={southpoint} />
        <Logo image={lynn} />
        <Logo image={signatura} />
      </div>

      <div className="w-full flex flex-wrap">
        <Logo image={realgrowth} />
        <Logo image={cosmopolitan} />
        <Logo image={calgrom3} />
        <Logo image={ryanjoffe} />
      </div>

      <div className="w-full flex flex-wrap">
        <Logo image={innercityflats} />
        <Logo image={houss} />
      </div>
    </Flickity>
  )
}

export default DevelopersTrustedBrands
