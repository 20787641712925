import React from "react"
import { Disclosure } from "@headlessui/react"

const faqs = [
  {
    question:
      "I’m already doing Facebook marketing for my development, why should I use Flow?",
    answer:
      "Without automation and targeting through Flow, promoting posts for your business can lead to loads of wasted time and advertising spend. Flow’s automation system converts all your assets into targeted campaigns relevant to your exact audience.",
  },
  {
    question: "How do you target the right audiences?",
    answer:
      "Based on industry and user data, we know where the most appropriate buyers and tenants are across Facebook and millions of web pages. Through retargeting people who have shown interest in your brand and properties, we build your ideal buyer profile based on your stock and listing suburbs to find and convert the highest quality leads for your team to close.",
  },
  {
    question:
      "I already have a marketing agency running ads for me, why should I use Flow?",
    answer:
      "Flow is complimentary to your marketing team and agency - and used as a tool to scale their efforts. Without the automation that Flow provides, promoting posts for your business can lead to loads of wasted time and advertising spend. The Flow system does the heavy lifting for you, pulling in real-time property feeds at scale, finding highly targeted buyers / tenants based on historical data, automatically converting your feeds into advanced ad campaigns, based on all the information provided in your listings, and providing real-time reporting and lead assignment through the Flow dashboard.",
  },
  {
    question: "How do I monitor my campaign performance?",
    answer:
      "Your campaign performance is crucial to informing greater business decisions, that’s why we provide you and your agents with a real-time reporting dashboard and lead management system that showcases your campaigns performance in a digestible and informative way. The dashboard presents all your leads, spend, agent lead volumes and so much more!",
  },
  {
    question: "How do I receive leads & can I use my own CRM?",
    answer:
      "Did you know that the chances of converting a hot lead deteriorates by 40% every 5 mins? This means that it is vital that your agents receive the leads immediately after the enquiry is made. Agents will receive their leads via SMS and email, with a WhatsApp to start a chat - enabling them to reach out directly to the applicant as fast and efficiently as possible. You can review lead details in your Flow account, and in your CRM, if you choose to integrate it with Flow. Flow seamlessly connects to 100s of CRM’s automatically and pushes the most relevant data for your sales teams to close.",
  },
  {
    question: "Who determines my budget? How much does it cost?",
    answer:
      "Flow does NOT work on a pay per listing model, but rather determines your budget based on your goal and volume of leads required. Our account management team is here to assist you with allocating budget according to the project outcome of the campaign's performance. You can allocate budget according to suburbs, listing agents, listing type or even price point.",
  },
  {
    question: "I’m still in the process of development, can I do anything now?",
    answer:
      "This is the best time to start building your presence and awareness about the development. Flow leverages renders and development specs to convert into multiple ad formats, to showcase to future buyers, investors and tenants. This is a strategic time for your development that will help boost the overall sales success come launch day, by building, educating and warming-up your future prospects.",
  },
  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function DevelopersFaq() {
  return (
    <div className="bg-gray-50">
      <div className="container mx-auto">
        <div className="w-full divide-y-2 divide-gray-200">
          <dl className="mt-6 space-y-4 divide-y divide-gray-300">
            {faqs.map(faq => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <i
                            className={classNames(
                              open
                                ? "fas fa-minus-circle"
                                : "fas fa-plus-circle",
                              "h-6 w-6 transform text-gray-800"
                            )}
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
