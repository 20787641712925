import React from "react"
import ReactDOM from "react-dom"
import Flickity from "react-flickity-component"

import "../assets/css/flickity.css"

import nadinepretorius from "../images/testimonials/nadinepretorius.png"
import jessicahofmeyr from "../images/testimonials/jessicahofmeyr.png"
import trentsalzma from "../images/testimonials/trentsalzma.png"
import winniedeklerk from "../images/testimonials/winniedeklerk.png"

import Forge from "../images/logos/properties/forge.png"
import Quorom from "../images/logos/properties/quorum.png"
import InnerCityFlats from "../images/logos/properties/innercityflats.png"
import houss from "../images/logos/properties/houss.png"

const Testimonial = ({ testimonial, image, name, title, logo }) => {
  return (
    <section className="container mx-auto px-5 md:px-0 self-center">
      <div className="w-full p-8 md:p-16">
        <p className="font-medium tracking-tight text-gray-700 text-md md:text-xl">
          “{testimonial}”
        </p>
        <div className="flex mt-4 leading-none">
          <div className="rounded-full bg-orange-500 border-8 border-orange-200 h-24 w-24 p-2 mr-4 flex-shrink-0">
            <img className="w-16 h-16" src={image} alt="" />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="mt-1 text-left self-center mr-4">
              <p className="">{name}</p>
              <p className="text-sm text-gray-400 mt-1">{title}</p>
            </div>
            <img className="self-center h-16 mr-auto" src={logo} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

function TestimonialsAgencies() {
  return (
    <Flickity className="w-full testimonials">
      <Testimonial
        testimonial="Flow delivered on their solution by helping us reach potential buyers consistently and with ease. They were able to get our campaign set up in a matter of hours. The whole process was effortless from start to finish. Our return on investment was tremendous. With their help we have been able to make this development a success."
        image={nadinepretorius}
        name="Nadine Pretorius"
        title="Principal, Forge"
        logo={Forge}
      />

      <Testimonial
        testimonial="Performance Marketing with the Flow platform is changing the game across the property industry. We heard about how FlowFuel instantly automated listings and generated leads via social media platforms in the residential market, and we wanted to see if the platform could work for us in the commercial space – and we closed two commercial deals inside our first month. Flow really is revolutionising the way South Africans find property with instant, automated tools – and the tech does all the heavy lifting for us"
        image={trentsalzma}
        name="Trent Salzma"
        title="COO: Quorum"
        logo={Quorom}
      />

      <Testimonial
        testimonial="Billboards and streetpole ads are dead. After understanding how FlowFuel instantly automated our listings and targeted & optimised them for social media platforms, we directed 100% of our marketing budget into the platform – and our lead volumes have never been higher. It showed us that we’d effectively been wasting our marketing spend – and time – on other ineffective channels. Now leads come to us and our team can focus on converting them, not hunting for them"
        image={winniedeklerk}
        name="Winnie de Klerk"
        title="Marketing Manager - Inner City Flats"
        logo={InnerCityFlats}
      />

      <Testimonial
        testimonial="FlowFuel and FlowBrand have given us access to meaningful, measurable results across social media platforms by automating our listings. The ability to switch up our campaigns at a moment’s notice helps us optimise marketing spend and laser-focus our campaigns. It’s incredible optimisation and targeting has really focussed our media spend across our developments, finding the right people, in the right area, at the right price point, to target on the platforms where they spend hours of every day. It’s doing the job of an entire team and the fact that it’s ‘always-on’ means that it drives leads for us, every hour of every day"
        image={jessicahofmeyr}
        name="Jessica Hofmeyr"
        title="Head of Houss and Executive at Century Developments"
        logo={houss}
      />

    </Flickity>
  )
}

export default TestimonialsAgencies
