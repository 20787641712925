import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import BrandsDevelopers from "../components/brands_developers"
import TestimonialsAgencies from "../components/testimonials_developers"
import DevelopersFaq from "../components/for_developers_faq"

import flowfuel from "../images/flowfuel.mp4"
import wave from "../images/wave.svg"
import forPropertyDevelopers from "../images/for_property_developers.png"
import SuburbRelationship from "../images/suburb_relationship.png"
import AgencyProfile from "../images/agency_profile.png"
import LeadsDiagram from "../images/leads_diagram.png"
import LeadAnalyticsDashboard from "../images/lead_analytics_dashboard.png"
import CasestudyRemax from "../images/casestudy_remax.png"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Automated developement digital marketing."
      description="Automatically run campaigns on the largest social platforms in
      the world to drive a high volume of quality sales leads to your
      agents, while keeping your competition at bay."
      image={SuburbRelationship}
    />

    <section className="w-full md:px-10 relative">
      <div className="container mx-auto py-5">
        <header className="flex flex-col-reverse md:flex-row w-full top-0">
          <div className="flex w-full md:w-1/2 mt-12 md:mt-0">
            <div className="self-center text-center md:text-left">
              <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight md:mx-0 animated fadeIn">
                <span className="wrap">Automated</span> development digital
                marketing.
              </h1>
              <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-md mx-auto mt-3 px-10 md:px-0">
                Automatically run campaigns on the largest social platforms in
                the world to drive a high volume of quality sales leads to your
                agents, while keeping your competition at bay.
              </p>

              <Link
                to="/book-demo"
                className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-5"
              >
                Book a demo <i className="fas fa-arrow-right ml-2"></i>
              </Link>
            </div>
          </div>

          <div className="w-full md:w-1/2 mx-auto lottieWidth">
            <video width="500" height="500" playsInline autoPlay loop muted>
              <source src={flowfuel} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </header>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section className="w-full mt-10 md:mb-32 px-10 md:px-0">
      <h3 className="text-xl text-gray-700 text-center mt-4">
        Trusted by the top property developers using Flow to grow their
        business.
      </h3>

      <div className="mt-10 md:w-3/5 mx-auto">
        <BrandsDevelopers />
      </div>
    </section>

    <section className="container mx-auto px-10 md:px-0">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto max-w-xl font-bold text-gray-700 leading-none">
          Reach millions of buyers & renters right where they are
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          Join leading brands globally who choose to grow their business on
          Facebook, Instagram and Top Websites. In fact over 70% of all digital
          media budgets are spent here because it works.
        </p>
      </div>
    </section>

    <section className="container mx-auto py-10 md:py-16">
      <div className="w-full overflow-hidden bg-gray-100 rounded-xl">
        <TestimonialsAgencies />
      </div>
    </section>

    <section className="container mx-auto px-10 md:px-0">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl leading-normal mx-auto font-bold text-gray-700 leading-none">
          See how Flow can work for your{" "}
          <span className="wrap">development</span>
        </h2>
      </div>
    </section>

    <section className="mt-12 md:mt-0 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Show your development across Facebook, Instagram & the web
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Your development listings are automatically converted to Facebook
            and Instagram ads, giving buyers an immersive experience of the
            features in your units.
          </p>

          <div className="w-full flex text-left max-w-sm mt-12">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Manage listings or Sync with your listings management system.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Automated ads creation on 16+ placements.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Multiple ad formats with immersive video and carousel.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pl-20"
            src={forPropertyDevelopers}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="bg-gray-100 mt-12 md:mt-12 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row-reverse">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Automatically target home buyers and investors
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Your ads are targeted to first time, returning and investor buyers
            through algorithmic targeting, and retargeting of your website
            traffic and lists.
          </p>
        </div>
        <div className="w-full md:w-1/2 md:pr-12">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pr-20"
            src={SuburbRelationship}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="mt-12 md:mt-0 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Branded Development Lead pages.
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            You get a Branded Lead Page with your brand and your listings,
            optimised for social and to drive mobile conversions.
          </p>

          <div className="w-full flex text-left max-w-sm mt-12">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Exclusively showing your listings and brand.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Keep your competition out! Drive leads to 100% of your property
                listings.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Showcasing different units through features, videos and
                Matterport walkthroughs.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pl-20"
            src={AgencyProfile}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="bg-gray-100 mt-12 md:mt-12 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row-reverse">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Leads delivered for performance & deliverability
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Listing agents are automatically assigned leads based on their
            portfolio through Email, SMS and the Flow portal. Leads are
            instantly notified of their enquiry through Email, SMS and WhatsApp
            with their listing agents and property details.
          </p>

          <div className="w-full flex text-left max-w-sm mt-12">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Quick integration to hundreds of CRMs, fits in seamlessly with
                your operation.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Configurable workflows of leads to your agents via SMS and
                email.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 md:pr-12">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pr-20"
            src={LeadsDiagram}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="mt-12 md:mt-0 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Full view of your performance from one simple dashboard
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Get deep insights into your leads so you can better shape your
            business for performance and closing more deals.
          </p>

          <div className="w-full flex text-left max-w-sm mt-12">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Real time analytics of who is interested in your properties and
                brand.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Break it down by urgency, profile, property, agent, suburb and
                more.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Take action on insights from analytics to maximise profit.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pl-20"
            src={LeadAnalyticsDashboard}
            alt=""
          />
        </div>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section className="container mx-auto px-10 md:px-0">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto font-bold text-gray-700 leading-none">
          Success Stories
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          See how businesses similar to yours are growing with Flow, building
          trust in their brand and generating top quality buyer, seller and
          tenant leads.
        </p>
      </div>
    </section>

    <section className="bg-gray-100 mt-12 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col md:flex-row">
        <div className="w-full md:mr-10">
          <img className="" src={CasestudyRemax} alt="" />
        </div>
        <div className="w-full">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Driving new development sales through quality buyer leads
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Mods @ Willows is a brand new residential complex in the heart of
            Pretoria East’s Equestria, offering modern, contemporary living at
            affordable prices - ideal for investors and first time home buyers.
            RE/MAX Infoglobe’s Nerine Geldenhuis approached Flow to assist with
            capturing new buyer leads for this development when it came onto the
            market.
          </p>
          <div className="flex mt-4">
            <div className="w-1/3">
              <div className="text-2xl font-bold text-orange-500">50%</div>
              <div className="text-sm text-gray-500">
                more quality leads generated than traditional sales portals
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-2xl font-bold text-orange-500">7</div>
              <div className="text-sm text-gray-500">properties sold</div>
            </div>
            <div className="w-1/3">
              <div className="text-2xl font-bold text-orange-500">5.5x</div>
              <div className="text-sm text-gray-500">return on investment</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container mx-auto text-center">
      <Link
        to={`/case-studies`}
        className="inline-block bg-orange-200 text-orange-500 rounded-lg p-3 cursor-pointer mt-5 md:mt-20"
      >
        See more case studies <i className="fas fa-arrow-right ml-2"></i>
      </Link>
    </section>

    <section className="container mx-auto px-10 md:px-0 my-10 md:my-32">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto font-bold text-gray-700 leading-none">
          Frequently asked questions
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          Real questions, from real partners{" "}
        </p>
      </div>

      <DevelopersFaq />
    </section>

    <section className="bg-gray-100 mt-12 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto text-center">
        <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight md:mx-0 animated fadeIn">
          Join leading developers using Flow to find <span className="wrap">buyer</span> & <span className="wrap">tenant</span> leads for their developments and portfolios
        </h1>
        <Link
          to="/book-demo"
          className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-5"
        >
          Book a demo <i className="fas fa-arrow-right ml-2"></i>
        </Link>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default IndexPage
